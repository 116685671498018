<template>
    <el-dialog class="dialog" :visible.sync="dialogVisibleRef" :width="dialogWidth" :style="{ 'min-width': dialogWidth }"
        center :show-close="false" @close="dialogVisibleRef = false" append-to-body>
        <!-- <el-divider /> -->
        <template #title>
            <div class="dialog-header-content">
                <h4 class="dialog-header-content-title">{{ dialogTitle }}</h4>
                <button class="dialog-header-content-close" @click.prevent="dialogVisibleRef = false">
                </button>
            </div>
            <el-divider />
        </template>
        <slot></slot>
        <template #footer>
            <span class="dialog-footer" v-if="showFooter">
                <el-button class="cancel" @click="cancelAction">{{ cancelButtonText }}</el-button>
                <el-button class="primary" type="primary" @click="primaryAction" :loading="buttonLoading">{{
                    primaryButtonText }}</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import {
    Dialog,
    Divider,
    Button
} from 'element-ui'
export default {
    components: {
        ElDialog: Dialog,
        ElDivider: Divider,
        ElButton: Button,
    },
    props: {
        dialogTitle: {
            type: String,
            default: '',
            required: false,
        },
        dialogWidth: {
            type: String,
            default: '',
            required: false,
        },
        dialogVisible: {
            type: Boolean,
            default: false,
            required: false,
        },
        cancelButtonText: {
            type: String,
            default: '',
            required: false,
        },
        primaryButtonText: {
            type: String,
            default: '',
            required: false,
        },
        buttonLoading: {
            type: Boolean,
            default: false,
            required: false,
        },
        showFooter: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data() {
        return {

        }
    },
    computed: {
        dialogVisibleRef: {
            get() {
                return this.dialogVisible
            },
            set(val) {
                this.$emit('update:dialogVisible', val)
            }
        }
    },
    methods: {
        primaryAction() {
            this.$emit('clickPrimary')
        },
        cancelAction() {
            this.$emit('clickCancel')
        }
    },
}
</script>
<style scoped lang='scss'>
.dialog {
    &-header-content {
        height: 62px;
        margin: 0px 27px;
        display: flex;
        align-items: center;
        justify-content: space-between;


        &-title {
            display: block;
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            line-height: 22px;
        }

        &-close {
            background-color: transparent;
            border: 0px;
            width: 30px;
            height: 30px;

            background: {
                image: url('~@/assets/imgs/dialog-close.png');
                repeat: no-repeat;
                position: center;
            }

            ;
        }
    }

    :deep(.el-dialog__header) {
        padding: 0px;
        margin: 0px;
    }

    :deep(.el-dialog__footer) {
        padding-right: 64px;
        padding-bottom: 22px;
        padding-top: 0px;
    }

    :deep(.el-dialog__body) {
        padding: 0px;
        margin: 0px;
    }

    .el-divider {
        margin: 0px;
    }

    .dialog-footer {
        .cancel {}

        .primary {
            background-color: #2779FF;
        }
    }
}
</style>