<template>
  <div class="container">
    <UploadFileList class="upload" ref="uploadFileListRef" :originFiles.sync="originFiles"></UploadFileList>
    <ReportFooter class="footer" primaryButtonText="确定" cancelButtonText="取消" @clickPrimary="clickPrimary"
      @clickCancel="clickCancel"></ReportFooter>
  </div>
</template>
  
<script>
export default {
  components: {
    UploadFileList: () => import('@c/user-main/common/upload-examination-report/upload-file-list.vue'),
    ReportFooter: () => import('@c/user-main/common/upload-examination-report/report-footer.vue')
  },
  inject: ['hide', 'onSucceed'],

  props: {
    data: Object,
  },

  data() {
    return {
      originFiles: []
    }
  },

  watch: {
    data : {
      handler(newVal, oldVal) {
        this.originFiles = newVal?.file ?? []
      },
      immediate: true
    }
  },

  methods: {
    clickPrimary() {
      if ((this.$refs.uploadFileListRef?.fileList?.length ?? 0) === 0) {
        this.$message.error('请上传文件')
        return
      }

      if (this.$refs.uploadFileListRef?.fileList?.some(el => el.status == 'fail')) {
        this.$message.error('请删除上传失败文件，再进行保存')
        return
      }

      if (this.$refs.uploadFileListRef?.fileList?.some(el => el.status == 'uploading')) {
        this.$message.error('文件还在上传中，请上传成功后再进行保存')
        return
      }
      if (this.$refs.uploadFileListRef?.fileList?.some(el => el.showName == '')) {
        this.$message.error('文件名不能为空')
        return
      }
      const params = {
        categoryId: 0,
        categoryName: "睡眠报告",
        file: this.$refs.uploadFileListRef?.fileList?.map(el => ({
          name: el.showName,
          url: el.url
        }))
      }
      this.onSucceed('sleep-file', params)
    },
    clickCancel() {
      this.hide()
    }
  },
}
</script>
<style scoped lang='scss'>
.container {
  width: 100%;
  height: 100%;

  .upload {
    width: 100%;
    height: calc(100% - 60px);
  }

  .footer {
    height: 60px;
  }
}
</style>