<template>
    <DialogContainer :dialogTitle="!options.edit ? '上传检验报告' : '编辑'" dialogWidth="725px"
        :dialogVisible.sync="dialogVisibleRef" :showFooter="false">
        <!-- <SideBar></SideBar> -->
        <div class="content-container" v-if="dialogVisible" :class="{'edit-status': options.edit}">
            <keep-alive>
                <component :is="currentComponent" :data="options.data"/>
            </keep-alive>
        </div>
    </DialogContainer>
</template>

<script>
import DialogContainer from '@c/user-main/common/dialog-container.vue'
import SideBar from '@c/user-main/common/upload-examination-report/sidebar.vue'
import Other from '@c/user-main/common/upload-examination-report/other.vue'
import File from '@c/user-main/common/upload-examination-report/sleep-child/file-import.vue'
import Database from '@c/user-main/common/upload-examination-report/sleep-child/database-import.vue'
import Manual from '@c/user-main/common/upload-examination-report/sleep-child/manual-import.vue'

export default {
    components: {
        DialogContainer,
        SideBar,
        File,
        Database,
        Manual,
        Other
    },
    props: {
        dialogVisible: Boolean,
        options: Object
    },
    computed: {
        dialogVisibleRef: {
            get() {
                return this.dialogVisible
            },
            set(val) {
                this.$emit('update:dialogVisible', val)
            }
        },
        currentComponent() {
            if (!this.options.edit) {
                return 'SideBar'
            } else {
                if (this.options.type == 'sleep-file') { return 'File' }
                else if (this.options.type == 'sleep-database') { return 'Database' }
                else if (this.options.type == 'sleep-manual') { return 'Manual' }
                else { return 'Other' }
            }
        }
    },
    data() {
        return {
        }
    },
    provide() {
        return {
            hide: this.hide,
            onSucceed: this.onSucceed
        };
    },
    methods: {
        hide() {
            this.dialogVisible = false
        },
        show() {
            this.dialogVisible = true
        },
        onSucceed(type, params) {
            this.$emit("onSucceed", type, params, this.options.edit);
        }
    },
}
</script>
<style scoped lang='scss'>
.content-container {
    width: 100%;
    height: 370px;
}
.edit-status {
    padding: 10px 15px 10px;
    box-sizing: border-box;
}
</style>