<template>
    <div class="sleep-container">
        <el-menu class="menu" :default-active="activeIndex" mode="horizontal" active-text-color="#1677FF" @select="handleSelect">
            <el-menu-item index="1">
                <template slot="title">
                    <span class="menu-title">文件导入</span>
                </template>
            </el-menu-item>
            <el-menu-item index="2">
                <template slot="title">
                    <span class="menu-title">数据库导入</span>
                </template>
            </el-menu-item>
            <el-menu-item index="3">
                <template slot="title">
                    <span class="menu-title">手动输入</span>
                </template>
            </el-menu-item>
        </el-menu>
        <div class="content-container">
            <transition name="fade-slide" mode="out-in" appear>
                <keep-alive>
                    <component :is="componentArr[Number(activeIndex)-1]" :data="data"/>
                </keep-alive>
            </transition>
        </div>
    </div>
</template>

<script>
import { Menu, Submenu, MenuItem } from 'element-ui'
import File from '@c/user-main/common/upload-examination-report/sleep-child/file-import.vue'
import Database from '@c/user-main/common/upload-examination-report/sleep-child/database-import.vue'
import Manual from '@c/user-main/common/upload-examination-report/sleep-child/manual-import.vue'
export default {
    components: {
        ElMenu: Menu,
        ElSubmenu: Submenu,
        ElMenuItem: MenuItem,
        File,
        Database,
        Manual
    },

    props: {
        data: Object,
    },

    data() {
        return {
            activeIndex: '1',
            componentArr: ['File', 'Database', 'Manual']
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        handleSelect(key, keyPath) {
            this.activeIndex = key
        }
    },
}
</script>
<style scoped lang='scss'>
.menu-title {
    font-size: 14px;
    font-weight: 500;
    // color: #1677FF;
}

.sleep-container {
    width: 100%;
    height: 100%;

    .menu {
        width: 100%;
        height: 36px;

        :deep(.el-menu-item) {
            height: 36px;
            line-height: 36px;
            padding: 0;
            margin: 0 20px;
            &.is-active {
                border-bottom-color: #1677FF !important;
                color: #1677FF !important;
            }
        }
    }

    .content-container {
        width: 100%;
        height: calc(100% - 37px);
    }
}
</style>