<template>
  <div class="container">
    <el-form ref="formRef" label-width="100px" class="form">
      <el-form-item label="报告分类：" required>
        <el-select v-model="otherOption" placeholder="请选择" :popper-append-to-body="false">
          <el-option v-for="(item, index) in otherSelectOptions" :key="item.value" :label="item.text" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <UploadFileList class="upload" ref="uploadFileListRef" :originFiles.sync="originFiles"></UploadFileList>
    <ReportFooter class="footer" primaryButtonText="确定" cancelButtonText="取消" @clickPrimary="clickPrimary"
      @clickCancel="clickCancel"></ReportFooter>
  </div>
</template>
  
<script>
import { Select, Option, Form, FormItem } from 'element-ui'
export default {
  components: {
    UploadFileList: () => import('@c/user-main/common/upload-examination-report/upload-file-list.vue'),
    ReportFooter: () => import('@c/user-main/common/upload-examination-report/report-footer.vue'),
    ElSelect: Select,
    ElOption: Option,
    ElForm: Form,
    ElFormItem: FormItem,
  },

  inject: ['hide', 'onSucceed'],

  props: {
    data: Object,
  },

  watch: {
    data: {
      handler(newVal, oldVal) {
        this.otherOption = newVal?.categoryId ?? null
        this.originFiles = newVal?.file ?? []
      },
      immediate: true
    }
  },

  data() {
    return {
      originFiles: [],
      otherOption: null,
      otherSelectOptions: []
    }
  },
  created() {
    this.getCategory()
  },
  methods: {
    async getCategory() {
      try {
        const data = await this.$api.getInspectionReportCategory({ ids: [] })
        this.otherSelectOptions = data?.map(el => ({
          value: el.categoryId,
          text: el.categoryName
        })) ?? []
      } catch (error) {

      }
    },
    clickPrimary() {
      if (!this.otherOption) {
        this.$message.error('请选择报告分类')
        return
      }

      if ((this.$refs.uploadFileListRef?.fileList?.length ?? 0) === 0) {
        this.$message.error('请上传文件')
        return
      }

      if (this.$refs.uploadFileListRef?.fileList?.some(el => el.status == 'fail')) {
        this.$message.error('请删除上传失败文件，再进行保存')
        return
      }

      if (this.$refs.uploadFileListRef?.fileList?.some(el => el.status == 'uploading')) {
        this.$message.error('文件还在上传中，请上传成功后再进行保存')
        return
      }

      if (this.$refs.uploadFileListRef?.fileList?.some(el => el.showName == '')) {
        this.$message.error('文件名不能为空')
        return
      }

      let params = {
        categoryId: this.otherOption,
        categoryName: this.otherSelectOptions.find(el => el.value == this.otherOption)?.text ?? '',
        file: this.$refs.uploadFileListRef?.fileList?.map(el => ({
          name: el.showName,
          url: el.url
        }))
      }
      this.onSucceed('other-file', params)
    },
    clickCancel() {
      this.hide()
    }
  },
}
</script>
<style scoped lang='scss'>
.container {
  width: 100%;
  height: calc(100% - 20px);

  .form {
    margin-top: 18px;
    height: 30px;

    :deep(.el-form-item) {
      margin-bottom: 0;

      .el-form-item__label {
        line-height: 30px;
      }

      .el-form-item__content {
        line-height: 30px;
      }
    }

    :deep(.el-select) {
      height: 30px;
    }

    :deep(.el-input__inner) {
      height: 28px;
      line-height: 28px;
    }

    :deep(.el-input__icon) {
      line-height: 28px;
    }
  }

  .upload {
    width: 100%;
    height: calc(100% - 108px);
  }

  .footer {
    height: 60px;
  }
}
</style>