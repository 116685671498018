<template>
    <div class="alert-container">
        <el-menu class="sidebar" :default-active="activeIndex" @select="handleSelect">
            <el-menu-item index="1">
                <template slot="title">
                    <span class="menu-title">睡眠报告</span>
                </template>
            </el-menu-item>
            <el-menu-item index="2">
                <template slot="title">
                    <span class="menu-title">其他报告</span>
                </template>
            </el-menu-item>
        </el-menu>
        <div class="content-container">
            <transition name="fade-slide" mode="out-in" appear>
                <keep-alive>
                    <component :is="activeIndex == 1 ? 'Sleep' : 'Other'" :data="data"/>
                </keep-alive>
            </transition>
        </div>
    </div>
</template>

<script>
import { Menu, Submenu, MenuItem } from 'element-ui'
import Sleep from '@c/user-main/common/upload-examination-report/sleep.vue'
import Other from '@c/user-main/common/upload-examination-report/other.vue'
export default {
    components: {
        ElMenu: Menu,
        ElSubmenu: Submenu,
        ElMenuItem: MenuItem,
        Sleep,
        Other
    },

    props: {
        data: Object,
    },

    data() {
        return {
            activeIndex: '1',
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        handleSelect(key, keyPath) {
            this.activeIndex = key
        }
    },
}
</script>
<style scoped lang='scss'>
.menu-title {
    font-size: 14px;
    font-weight: 500;
    // color: #1677FF;
}

.alert-container {
    display: flex;
    width: 100%;
    height: 370px;

    .sidebar {
        width: 110px;


        :deep(.el-menu-item):hover {
            // outline: 0 !important;
            color: #2E6BE6 !important;
        }

        :deep(.el-menu-item).is-active {
            // color: #fff !important;
            background-color: #ECF2FE !important;
        }

        :deep(.el-menu-item) {
            text-align: center;
        }
    }

    .content-container {
        width: 100%;
        height: 100%;
    }
}
</style>